.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.row {
  max-width: 100%;
  --bs-gutter-x: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 6px;
  padding-right: 6px;
}

.sr-only {
  clip: rect(0,0,0,0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#mobile-navmenu button.close {
  padding: 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  margin-top: -40px;
  font-weight: 600;
  color: #ffffff;
}

#modalSearch button.close {
  padding: 5px;
  background: transparent;
  border: none;
  font-size: 35px;
  margin-top: -40px;
  font-weight: 600;
  color: #8b8b8b;
}

.admin-modal-delete button.close {
  padding: 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  margin-top: -20px;
  font-weight: 600;
  color: #8b8b8b;
}

#adminModaladdWidget button.close, #adminModaleditWidget button.close {
  padding: 5px;
  background: transparent;
  border: none;
  font-size: 35px;
  margin-top: -40px;
  font-weight: 600;
  color: #ebebeb;
}

.add-widget-modal .mt-3.modal-footer {
  background: #1c1c22;
}

img {
  max-width: 100%;
  height: auto;
}

.btn-admin {
  /* display: none; */
  margin-top: 5px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

.calendar-header-title {
  text-transform: capitalize;
  font-size: 1.555rem;
  line-height: 1.428;
  font-weight: 600;
}

.calendar-day-header {
  line-height: .5;
}

.calendar-small-day-name {
  padding-left: 12px;
}

.section.section-muted {
  background: #f2f7fc;
}

.it-header-block-title.calendar-header-block {
  color: #fff;
  padding: 0.5em 0;
  border-radius: 4px 4px 0 0;
}

@media(min-width:576px) {

  .container,
  .container-sm {
      max-width: 540px
  }
}

@media(min-width:768px) {

  .container,
  .container-md,
  .container-sm {
      max-width: 668px
  }
}

@media(min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
      max-width: 960px
  }
}

@media(min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
      max-width: 1184px
  }
}

@media(min-width:1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
      max-width: 1320px
  }
}

.it-access-top a {
  font-size: .875em;
  font-weight: 600;
}

.it-access-top .icon {
  fill: #fff;
  width: 25px;
}

.news-title-link, .service-title-link {
  text-decoration: none;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .row [class*=col-]>.card-wrapper>.card.sticky-news-wrapper.card {
      height: 95%;
  }
}

@media (min-width: 1401px) {
  .row [class*=col-]>.card-wrapper>.card.sticky-news-wrapper.card {
      height: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-wrapper.card-teaser-wrapper.card-teaser-block-3>.card-teaser {
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .card-wrapper.card-teaser-wrapper.card-teaser-block-3>.card-teaser {
    flex: 0 0 32%;
  }
}

.news-description {
  font-size: .889em;
}

.fw-semibold {
  font-weight: 600!important;
}

.font-monospace {
  font-family: Roboto Mono,monospace!important;
}

.news-image-description, .event-image-description {
  color: #656566;
  font-size: 90%;
}

.attachment-box {
  padding: 24px;
  border: 1px solid #e9e6f2;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  border-radius: 4px!important;
  height: 100%;
  word-wrap: break-word;
}

@media (max-width: 991px) {
  .navbar.it-top-navscroll {
    position: relative;
  }
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
  font-weight: 400;
}

.news-content-wrapper, .structure-content-wrapper, .staff-profile-content-wrapper, .sector-content-wrapper, .office-content-wrapper, .event-content-wrapper, .document-content-wrapper, .gallery-content-wrapper, .places-content-wrapper, .custom-widget-content-wrapper, .stats-content-wrapper {
  border-top: 1px solid #e9e6f2;
}

.news-section-content, .structure-section-content, .staff-profile-section-content, .sector-section-content, .office-section-content, .event-section-content, .document-section-content, .gallery-section-content, .places-section-content, .custom-widget-section-content, .stats-section-content {
  border-left: 1px solid #e9e6f2;
  padding: 3rem;
}

@media (max-width: 424px) {
  .news-section-content, .structure-section-content, .staff-profile-section-content, .sector-section-content, .office-section-content, .event-section-content, .document-section-content, .gallery-section-content, .places-section-content, .custom-widget-section-content, .stats-section-content {
    padding: 1rem;
  }
}

.attachment-box a {
  font-weight: 600;
}

.page-title {
  padding-left: 14px;
  padding-right: 14px;
}

.page-text-content {
  padding-top: 15px;
}

.breadcrumb-container .breadcrumb .breadcrumb-item {
  font-size: .899em;
  font-weight: 400;
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
  font-weight: 400;
}

.breadcrumb-container .breadcrumb .breadcrumb-item.active a {
  color: #656566;
  text-decoration: none;
}

.latest-news-wrapper {
  margin-top: 32px;
}

.card-wrapper.card-teaser-wrapper>.card-teaser.latest-news-card {
  margin-top: -30px;
  margin-bottom: 50px;
}

.menu-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: .899em;
}

.link-list-wrapper ul li a {
  line-height: 2em;
}

.latest-news-page-news-section, .sticky-tags-page-section, .latest-events-page-events-section, .latest-places-page-places-section {
  background-color: #eceff1;
  padding: 50px;
}

@media (max-width: 500px) {
  .latest-news-page-news-section, .sticky-tags-page-section, .latest-events-page-events-section, .latest-places-page-places-section {    
    padding: 0;
    padding-top: 20px;
  }
}

.it-grid-item-wrapper.it-grid-item-overlay .img-responsive-wrapper:after {
  content: "";
  display: block;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tag-hero-wrapper {
  min-height: 400px;
  background: #eceff1;
}

.tag-hero-wrapper.it-bottom-overlapping-content {
  margin-bottom: -15em;
}

@media (max-width: 459px) {
  .tag-hero-wrapper.it-bottom-overlapping-content {
    margin-bottom: -20em;
  }
}

.tag-managedby-card {
  border: 1px solid #e9e6f2;
  border-left: 8px solid rgba(0,122,82,.1);
  padding: 16px 24px;
  border-radius: 5px;
}

.title-xsmall-semi-bold {
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.6;
}

@media (min-width: 576px) {
  .title-xsmall-semi-bold {
    font-size: .7777777778rem;
  }
}

.staff-card-col {
  margin-bottom: 10px;
}

.staff-card {  
  border: 1px solid #ebebeb;
  border-left: 8px solid rgba(0,122,82,.1);
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  height: 100%;
}

.staff-role-title {
  padding-left: 10px;
  color: #707e7b;
  margin-bottom: 50px;
}

.staff-profile-img {
  max-height: 500px;
}

.structure-card-box, .office-card-box, .sector-card-box, .place-card {
  border: 1px solid #e9e6f2;
  border-left: 8px solid rgba(0,122,82,.1);
  border-radius: 5px;
}

.card.structure-card-box:after, .card.office-card-box:after, .card.sector-card-box:after, .card.document-card-box:after, .card.place-card-box:after, .card.card-contacts:after {
  display: none;
}

.step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.date-step {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  width: 11%;
  color: #796c31;
  font-family: "Roboto Mono",monospace;
  font-weight: 500;
  border-right: 1px solid #796c31;
}

.date-step .date-step-giorno {
  font-size: 1.2em;
  line-height: 1.1;
}

.scheda-step {
  border: 0;
  border-left: 8px solid #eaf2ec;
  padding: 0 24px;
  margin-bottom: 32px;
  vertical-align: middle;
  min-height: 80px;
  -webkit-box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
  -moz-box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.1);
  box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
  font-size: 1em;
  display: table;
  width: 100%;
  background: #fff;
  margin-left:  20px;
}

.scheda-step-testo {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  font-family: "Titillium Web",Geneva,Tahoma,sans-serif!important;
  font-size: .889em;
  padding: 16px 0;
}

.step .date-step .pallino {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 35px;
  right: -5px;
  background-color: #796c31;
  border-radius: 50%;
}

.step .date-step:after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  top: 40px;
  right: -5px;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  border-radius: 50%;
  background-color: #796c31;
}

.file-description, .card-description {
  font-family: Lora,Georgia,serif;
  font-size: .7777777778rem;
  line-height: 1.2rem;
  color: #435a70;
}

.document-category {
    letter-spacing: 1px;
    font-size: .778em;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.2em;
}

.document-date {
  font-size: .899em;
}

.swiper {
  height: 100%;
}

.swiper-slide {
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  height: auto !important;
  background: #fff;
}

@media (max-width: 500px) {
  .swiper-slide {
    width: 100% !important;
  }
}

@media (min-width: 501px) and (max-width: 1023px) {
  .swiper-slide {
    width: 50% !important;
  }
}

.swiper-content {
  height: 100% !important
}

.tags-home-hero-wrapper {
  min-height: 260px;
  z-index: -1;
}

.tags-home-content {
  margin-top: -200px;
}

.tags-home-title {
  color: #fff;
}

.tags-home-icon-wrapper {
  background: #e6efef;
  width: 45px;
  text-align: center;
  border-radius: 50px;
  padding: 5px 0px;
}

.tags-home-icon {
    width: 24px;
    height: 24px;
}

.link-list-wrapper ul li a.tags-home-link {
  padding-left: 0;
  border-bottom: 1px solid #ebebeb;
}

.bg-green
{
	background-color: #2e7d32;
}

.bg-blue
{
	background-color: #1565c0;
}

.bg-red
{
	background-color: #9b0000;
}

.bg-grey
{
	background-color: #455a64;
}

.bg-brown
{
	background-color: #6d4c41;
}

.bg-purple
{
	background-color: #7e57c2;
}

.bg-orange
{
	background-color: #DC340A;
}

.bg-wine
{
	background-color: #880e4f;
}

.bg-light-blue 
{
    background-color: #24656D;
}

.aree-web {
  border-radius: 4px;
  color: #fff;
  padding: 16px;
  margin-bottom: 8px;
  display: table;
  width: 100%;
}

.aree-web .icon-web {
  display: table-cell;
  vertical-align: middle;
  width: 4rem;
  text-align: center;
}

.aree-web-icon {
  width: 58px;
  height: 58px;
}

.aree-web-title {
  font-size: 1.125em;
}

.before-content-hero-wrapper {
  min-height: 400px;
}

.it-hero-wrapper .it-hero-text-wrapper.before-content-hero-body {
  padding: 120px;
}

@media (min-width: 501px) and (max-width: 991px) {
  .it-hero-wrapper .it-hero-text-wrapper.before-content-hero-body {
    padding: 10px;
    padding-bottom: 100px;
  }
}

@media (max-width: 500px) {
  .it-hero-wrapper .it-hero-text-wrapper.before-content-hero-body {
      padding: 20px;
      padding-bottom: 100px;
      padding-top: 20px;
  }
}

@media (max-width: 374px) {
  .it-hero-wrapper .it-hero-text-wrapper.before-content-hero-body {
      padding: 20px;
      padding-bottom: 100px;
      padding-top: 80px;
  }
}

.btn-primary.before-footer-btn, .btn-primary.before-footer-btn:hover {
  color: #fff;
}

.main-pages-btn {
  margin-right: 25px;
}

@media (max-width: 991px) {
  .search-result-text-desktop {
    display: none;
  }
}

@media (min-width: 992px) {
  .search-filter-btn, .search-result-text-mobile {
    display: none;
  }
}

.search-result-card-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.search-home-form label.active {
  color: #fff;
}

.search-left-column {
  border-right: 1px solid #e9e6f2;
}

.modal.show .modal-dialog.search-modal {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
}

.search-modal .modal-content {
  height: 100%;
}

.modal .modal-dialog .modal-content .modal-body.search-modal-body {
  padding-top: 4%;
}

.search-modal-title {
  font-size: 2.667em;
  padding-top: 80px;
  padding-bottom: 80px;
  display: block;
  color: #555;
  text-transform: none;
  font-weight: 700;
}

.search-modal-title svg {
  width: 52px;
  height: 52px;
  cursor: pointer;
}

#input-group-search-header {
  height: 64px;
  font-size: 2.222em;
}

.input-group .input-group-prepend~label.search-header-label {
  font-size: 2.222em;
  padding-top: 10px;
  left: 3.25rem;
  color: #9b9b9b;
  font-weight: 400 !important;
}

.input-group .input-group-prepend~label.search-header-label.active {
  font-size: 1rem;
  color: #435a70;
  font-weight: 600 !important;
}

.it-search-wrapper {
  cursor: pointer;
}

@media (max-width: 500px) {
  svg.icon.search-header-icon-prepend.icon-lg {
    width: 24px;
    height: 24px;
  }
  
  .search-modal-body.modal-body {
    padding: 0 !important;
  }

  .search-header-label {
    max-width: 100% !important;
    font-size: 1.3rem !important;
  }

  .search-input-group-append {
    width: 80px;
  }

  #button-search-home {
    padding-left: 25px !important;
  }
}

.poweredby {
  /* font-family: Helvetica; */
  font-size: 15px;
  padding: 1.5rem 1rem;
}

#mobile-navmenu {
  padding-left: 10px;
  margin-bottom: 20px;
}

.mobile-navmenu-logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.react-bootstrap-drawer-toc a .mobile-navmenu-logo-wrapper {
  margin-left: 20px;
}

.mobile-navmenu-title {
  font-size: 1.575em;
  text-transform: none;
}

.mobile-navmenu-title a {
  text-decoration: none;
  color: #fff;
}

.it-brand-text .row {
  align-items: center
}

.col-organization-name {
  flex-grow: 7;
}

.col-organization-logo {
  min-width: 90px;
}

@media (max-width: 767px) {
  .col-organization-logo {
    min-width: 60px;
  }

  /* .col-organization-logo img {
    width: auto;
    height: auto;
  } */

  .col-organization-name h2 {
    font-size: .9em !important;
  }

  .col-organization-name h3 {
    font-size: .7em !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .col-organization-logo {
    min-width: 70px;
  }

  /* .col-organization-logo img {
    width: 60px;
    height: 60px;
  } */
}

.mobile-navmenu-sidebar {
  width: 70%;
}

.mobile-navmenu-item {
  font-size: 1.333em;
}

.mobile-follow-us {
  font-size: .8em;
  display: inline-block;
}

.mobile-follow-us-list {
  display: inline-block;
  padding-left: 10px;
}

.it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
  padding-left: 0;
}

@media (max-width: 374px) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 30px;
  }
}

@media (min-width: 375px) and (max-width: 630px) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 54px;
  }
}

@media (min-width: 631px) and (max-width: 860px) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 32px;
  }
}

@media (max-width: 374px) {
  .navbar.has-megamenu.navbar.navbar-expand-lg {
    margin-left: -20px;
  }
}

@media (max-width: 991px) {
  .custom-navbar-toggler svg {
    width: 48px;
    height: 48px;
  }

  .navbar.has-megamenu.navbar.navbar-expand-lg {
    padding-top: 0;
  }
}

.text-error {
  color: #ff0027!important;
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .logged-user-menu-link {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: .8em;
}

.link-list-wrapper h3.logged-user-menu-header, .it-header-slim-wrapper .it-header-slim-wrapper-content .logged-user-menu-link {
  color: #888888;
}

.rating-wrapper {
  padding: 2rem;
  margin-top: 150px;
}

.rating-title, .contacts-title {
  font-size: 1.3333333333rem;
}

.sticky-news-img {
  object-fit: cover;
  max-height: 477px;
}

@media (max-width: 499px) {
  .sticky-news-img {
    max-height: 250px;
  }
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper .it-brand-text {
  padding-right: 0;
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon {
  width: 48px;
  height: 48px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}

@media (max-width: 991px){
  .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon {
    margin-left: 8px;
    background: transparent;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    transition: .3s;
  }
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon svg {
  fill: #fff;
  width: 48px;
  height: 48px;
}

@media (min-width: 992px) {
  .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon svg {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 991px){
  .it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon svg {
    fill: #fff !important;
  }
}

@media (max-width: 767px) {
  .footer-help-link li a {
    margin-bottom: 15px;
  }
}

.gallery-img {
  width: 100%;
  max-width: 200px;  
  height: 122px;  
  cursor: pointer;
  object-fit: cover;  
}

.lightbox-portal {  
  position: relative;
  z-index: 9999;
}

.lightbox-wrapper {
  background: rgba(0,0,0,.9);
}

.lightbox-icons {
  position: relative;
  z-index: 10000;
  cursor: pointer;
}

.lightbox-header {
  color: #fff;
  background: rgba(0,0,0,.4);
}

.custom-widget-hero-wrapper {
  margin-left: -6px;
  margin-right: -6px;
}

.add-widget-modal {
  margin: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
}

.add-widget-modal .modal-content {
  height: 100%;
  background: #1c1c22;
}

.add-widget-modal label.form-check-label, .add-widget-modal p.admin-label, .add-widget-modal .close, .add-widget-modal .css-b8ldur-Input {
  color: #ebebeb;
}

.add-widget-modal .admin-form-standard.form-group label, .widget-admin-label {
  color: #00bf7c;
}

.widget-admin-label {
  font-size: .7777777778rem !important;
  font-weight: 600;
}

.widget-admin-radio-label {
  color: #ebebeb !important;
}

.add-widget-modal .css-sjawnh-control, .add-widget-modal .css-duooqy-control {
  background: #1c1c22;
}

.add-widget-modal .css-26l3qy-menu div {
  font-size: .97em;
  color: #ebebeb;
  background: #27272e;
}

.add-widget-modal .css-3gnqon-singleValue {
  color: #ebebeb;
}

.admin-form.form-group label, .css-zsohrp-placeholder, .admin-form-standard.form-group label {
  z-index: 0;
  font-size: .7777777778rem;
}

.admin-label {
  font-size: .777em !important;
  font-weight: 600;
  color: #435a70;
}

.divider-dark {  
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
}

.divider-light {  
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 1;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
}

.widget-item-wrapper {
  border: 1px solid #e3e3e3;
  padding: 10px; 
  border-radius: 5px;
  cursor: pointer;
}

.widget-item-icon { 
  color: #ebebeb;
}

.widget-item-wrapper:hover, .widget-item-wrapper.active {
  background-color: #272c36;
}

.manage-widget-btn-wrapper {
  text-align: right;
  position: relative;
  z-index: 999;
}

.widget-edit-btn { 
  padding: 0;
  margin-right: 10px;
}

.admin-bar {
  background: #26262b;
  position: sticky;
  top: 0;
  z-index: 3;
}

@media (max-width: 991px) {
  .admin-bar {
    position: inherit;
  }
}

.admin-bar-btn, .admin-bar-btn:hover {
  color: #ebebeb; 
  font-size: .775em;
}

.admin-bar-btn .icon {
  fill: #ebebeb;
  vertical-align: text-top;
}

.admin-bar-dropdown {
  display: inline-block;
}

.admin-bar-dropdown span {
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
}

.admin-bar-dropdown .dropdown-menu.show, .admin-bar-dropdown .dropdown-menu:before {
  background: #26262b;
}

.admin-bar-dropdown .dropdown-menu.show .link-list-wrapper ul li a {
  color: #ebebeb;
}

input.widget-form.form-control {
  background: #1c1c22;
  color: #ebebeb;
}

.add-widget-sidebar::-webkit-scrollbar {
  width: .2em;
}

.add-widget-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.add-widget-sidebar::-webkit-scrollbar-thumb {
  background-color: #5c6f82;
  outline: 1px solid #1c1c22;
  border-radius: 10px;
}

.edit-mode {
  border: 1px dotted rgba(0,0,0,0.2);
  padding: 10px;
}

.add-css-label {
  color: #00bf7c;
}

.btn-add-css, .btn-add-css:hover {
  color: #ebebeb;
}

.btn-delete-css, .btn-delete-css:hover {
  color: #d96b6b;
}

.tags-main-pages-wrapper {
  background: #ebebeb;
}

a.sticky-read-more.read-more {
  position: inherit;
  margin-top: 20px;
}

.footer-images-wrapper {
  text-align: right;
  margin-top: 20px;  
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: -6px;
  margin-right: -12px;
  margin-left: -12px;
}

a.footer-image-link {
  margin-right: 20px;
  margin-left: 20px;
  text-decoration: none;
}

@media (max-width: 1024px) {

  .footer-images-wrapper {
    text-align: center;
  }

  .footer-images-wrapper img {
    margin: 10px;
  }

  a.footer-image-link {
    margin-right: 0;
    margin-left: 0;
    display: block;
    margin-bottom: 25px;
    margin-top: 25px;      
  }
}

.cookie-consent-bar {
  font-family: 'Titillium Web', sans-serif;
  font-size: .875em;
  z-index: 10000 !important;
}

.step-title {
  font-size: 1rem;
}

.contacts-list {
  list-style: none;
  padding-left: 0;
}

.sticky-top.navbar-wrapper {
  z-index: 2;
}

@media (max-width: 424px) {
  .avatar-wrapper.staff-card.avatar-extra-text {
    flex-direction: column;
  }

  .sector-card.card-body, .office-card.card-body {
    padding: 10px;
  }
}

.edit-widget-modal {
  --bs-modal-margin: 50px;
}

.edit-widget-modal button.close {
  padding: 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  margin-top: -20px;
  font-weight: 600;
  color: #8b8b8b;
}

.news-content-description iframe {
  max-width: 100%;
}

/* @media (max-width: 499px) {
  .news-content-description iframe {
      max-height: 380px;
  }
} */

.react-share-button {
  display: block;
  width: 100%;
  text-align: left;
}

.react-share-button:hover {
  text-decoration: underline;
  cursor: pointer;
}